import { createStore } from "vuex";
import themeLayout from "./modules/themeLayout/actionCreator";
import headerSearchData from "./modules/headerSearch/actionCreator";
import auth from "./modules/authentication/axios/actionCreator";
import xeroclient from "./modules/xeroclient/actionCreator";
import UserMaster from "./modules/usermaster/actionCreator";
import RoleMaster from "./modules/rolemaster/actionCreator";

import XeroContact from "./modules/masters/contact/actionCreator";
import xeroUnuseddata from "./modules/masters/unused/actionCreator";
import xerorepatInvoice from "./modules/masters/repeatinvoice/actionCreator";

import XeroUnreconciled from "./modules/balance/unreconciled/actionCreator";
import Xerocapitalinvoice from "./modules/balance/capitalinvoice/actionCreator";
import Xerowrongvaluebs from "./modules/balance/wrongvaluebs/actionCreator";

import Xerowrongmismatch from "./modules/ap/wrongmismatch/actionCreator";
import XeroPay from "./modules/ap/unallocatedPay/actionCreator";
import Xeropartialpay from "./modules/ap/partialpay/actionCreator";
import Xeropartialreceipt from "./modules/ap/partialreceipt/actionCreator";
import XeroPIDraft from "./modules/ap/draft/actionCreator";
import XeroDebit from "./modules/ap/debit/actionCreator";
import XeroCredit from "./modules/ap/credit/actionCreator";
import Xerosupplierinvoices from "./modules/ap/supplier_invoice/actionCreator";
import Xeropaymentexpensed from "./modules/ap/paymentexpensed/actionCreator";
import XeroUnallocateReciept from "./modules/ap/unRecipet/actionCreator"; 
import Xerodebtorinvoices from "./modules/ap/pi_debtor/actionCreator";

import XeroInvoice from "./modules/nominal/duplicate_invoice/actionCreator";
import Xeronominal from "./modules/nominal/nominal_inconsistent/actionCreator";
import Xeronominalvat from "./modules/nominal/nominal_vat_inconsistent/actionCreator";
import xeroIncorrectVat from "./modules/nominal/incorrect_vat/actionCreator";
import XeroSup from "./modules/nominal/contact_sup/actionCreator";
import XeroVat from "./modules/nominal/vat_inconsistent/actionCreator";
import XeroJournal from "./modules/masters/journal/actionCreator";
import XeroVatmismatch from "./modules/nominal/vat_mismatch/actionCreator";
import XeroVatjournal from "./modules/nominal/manualjournalwithvat/actionCreator";
import XeroLateEntries from "./modules/nominal/late_entries/actionCreator";
import XeroForeign from "./modules/nominal/xero_foreign/actionCreator";
import XeroAllocation from "./modules/nominal/xero_allocation/actionCreator";
import XeroNoVat from "./modules/nominal/xero_novat/actionCreator";

import XeroFainvoices from "./modules/dynamic/fa_invoices/actionCreator";
import XeroEntertainment from "./modules/dynamic/entertainment_expenses/actionCreator";
import DynamicSupplierInvoice from "./modules/dynamic/supplierInvoice/actionCreator";
import DynamicValueInvoice from "./modules/dynamic/valueInvoice/actionCreator";
import XeroAccrual from "./modules/dynamic/potential_accrual/actionCreator";
import XeroPrepayments from "./modules/dynamic/potential_prepayments/actionCreator";
import XeroDainvoices from "./modules/dynamic/da_invoices/actionCreator";
import XeroVatlate from "./modules/dynamic/vat_late/actionCreator";


export default createStore({
  modules: {
    themeLayout,
    headerSearchData,
    auth,
    xeroclient,
    UserMaster,
    RoleMaster,
    XeroContact,
    xeroUnuseddata,
    XeroUnreconciled,
    XeroPay,
    Xeropartialpay,
    Xeropartialreceipt,
    XeroPIDraft,
    XeroDebit,
    XeroCredit,
    Xerosupplierinvoices,
    XeroInvoice,
    Xeronominal,
    XeroSup,
    XeroVat,
    XeroJournal,
    Xerodebtorinvoices,
    XeroUnallocateReciept,
    xerorepatInvoice,
    xeroIncorrectVat,
    Xeronominalvat,
    Xerowrongmismatch,
    XeroVatmismatch,
    Xerocapitalinvoice,
    Xerowrongvaluebs,
    XeroVatjournal,
    XeroLateEntries,
    Xeropaymentexpensed,
    XeroFainvoices,
    XeroEntertainment,
    DynamicSupplierInvoice,
    DynamicValueInvoice,
    XeroAccrual,
    XeroPrepayments,
    XeroVatlate,
    XeroDainvoices,
    XeroForeign,
    XeroAllocation,
    XeroNoVat,
  },
});
