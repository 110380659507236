import { createWebHistory, createRouter } from "vue-router";
import adminRoutes from "./AdminRoutes";
import overviewRoutes from "./OverviewRoutes";
import authRoutes from "./authRoutes";
import store from "@/vuex/store";
import { getItem } from "../utility/localStorageControl";
import Cookies from "js-cookie";

const routes = [
  {
    name: "Admin",
    path: "/",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/layout/withAdminLayout.vue"),
    children: [...adminRoutes],
    meta: { auth: false },
  },
  {
    name: "privacy",
    path: "/privacy-policy",
    component: () =>
      import( /* webpackChunkName: "admin" */ "@/layout/Privacy-policy.vue"),
    meta: { auth: true },
    props: true,
    
  },
  
  {
    name: "overview",
    path: "/overview",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/layout/OverviewLayout.vue"),
    children: [...overviewRoutes],
    meta: { auth: false },
    props: true,
  },
  {
    name: "Auth",
    path: "/auth",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/layout/withAuthLayout.vue"),
    children: [...authRoutes],
    meta: { auth: true },
  },
];

const router = createRouter({
  mode: "history",
  saveScrollPosition: true,
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && store.state.auth.login) {
    if (getItem("access_token") != null) {
      next({ to: "/" });
    } else {
      Cookies.remove("logedIn");
      next({ name: "login" });
    }
  } else if (!to.meta.auth && !store.state.auth.login) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
