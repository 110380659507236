const routes = [
  {
    path: 'login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/view/authentication/SignIn.vue'),
  },
  {
    path: 'xero-user',
    name: 'xero-user',
    component: () => import(/* webpackChunkName: "login" */ '@/view/authentication/SignXero.vue'),
  },
  {
    path: 'register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/view/authentication/Signup.vue'),
  },
  {
    path: 'forgotPassword',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/view/authentication/ForgotPassword.vue'),
  },
  /*{
    path: "/privacy-policy",
name: "privacy-policy",
    component: () =>
      import(
         webpackChunkName: "Dashboard" "@/view/dashboard/Privacy-policy.vue"
      ),
    props: true,
  },
  */
];

export default routes;
